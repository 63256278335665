export default [
  {
    path: "/",
    redirect: "/intro/Intro",
  },
  /**
   *  인트로
   */
  {
    path: '/intro/Intro',
    name: 'Intro',
    component: () => import('@/views/intro/Intro.vue'),
    meta: {
      layout: "full",
    },
  },
  /**
   *  개인정보처리방침
   */
  {
    path: '/intro/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/intro/PrivacyPolicy.vue'),
    meta: {
      layout: "full",
    },
  },
  /**
   *  error
   */
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () =>
      import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
];