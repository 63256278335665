/**
 *  단지 관리
 */
export default [
  /**
   *  건축 정보 관리
   */
  {
    path: '/sbd-mng/arc-inf/ArcInfDtl',
    name: 'ArcInfDtl',
    component: () => import('@/views/sbd-mng/arc-inf/ArcInfDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/arc-inf/ArcInfList',
    name: 'ArcInfList',
    component: () => import('@/views/sbd-mng/arc-inf/ArcInfList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/arc-inf/ArcInfUpd',
    name: 'ArcInfUpd',
    component: () => import('@/views/sbd-mng/arc-inf/ArcInfUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  홈넷 서버 관리
   */
  {
    path: '/sbd-mng/hnsvr-mng/HnsvrDtl',
    name: 'HnsvrDtl',
    component: () => import('@/views/sbd-mng/hnsvr-mng/HnsvrDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/hnsvr-mng/HnsvrAdd',
    name: 'HnsvrAdd',
    component: () => import('@/views/sbd-mng/hnsvr-mng/HnsvrAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/hnsvr-mng/HnsvrUpd',
    name: 'HnsvrUpd',
    component: () => import('@/views/sbd-mng/hnsvr-mng/HnsvrUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/hnsvr-mng/HnsvrList',
    name: 'HnsvrList',
    component: () => import('@/views/sbd-mng/hnsvr-mng/HnsvrList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  세대 정보
   */
  {
    path: '/sbd-mng/hsh-inf/HshDtl',
    name: 'HshDtl',
    component: () => import('@/views/sbd-mng/hsh-inf/HshDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/hsh-inf/HshList',
    name: 'HshList',
    component: () => import('@/views/sbd-mng/hsh-inf/HshList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/hsh-inf/HshUpd',
    name: 'HshUpd',
    component: () => import('@/views/sbd-mng/hsh-inf/HshUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  층간 소음 기기 관리
   */
  {
    path: '/sbd-mng/fns-dvc-mng/FnsDvcMngDtl',
    name: 'FnsDvcMngDtl',
    component: () => import('@/views/sbd-mng/fns-dvc-mng/FnsDvcMngDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/fns-dvc-mng/FnsDvcMngAdd',
    name: 'FnsDvcMngAdd',
    component: () => import('@/views/sbd-mng/fns-dvc-mng/FnsDvcMngAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/fns-dvc-mng/FnsDvcMngUpd',
    name: 'FnsDvcMngUpd',
    component: () => import('@/views/sbd-mng/fns-dvc-mng/FnsDvcMngUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sbd-mng/fns-dvc-mng/FnsDvcMngList',
    name: 'FnsDvcMngList',
    component: () => import('@/views/sbd-mng/fns-dvc-mng/FnsDvcMngList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  세대 단말기 관리
   */
  {
    path: '/sbd-mng/wallpad-mng/WallpadList',
    name: 'WallpadList',
    component: () => import('@/views/sbd-mng/wallpad-mng/WallpadList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
