import Vue from 'vue'
import Dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekday from 'dayjs/plugin/weekday'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ko from 'dayjs/locale/ko'

Dayjs.extend(utc)
Dayjs.extend(timezone)
Dayjs.extend(weekday)
Dayjs.extend(customParseFormat)
Dayjs.locale(ko)
Dayjs.tz.setDefault('Asia/Seoul')

Vue.use(Dayjs)
Vue.prototype.$dayjs = Dayjs
