export default [
  /**
   *  대시보드
   */
  {
    path: '/dashboard/Dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      /**
       *  LH 관리자
       */
      {
        path: "LhMngr",
        name: "LhMngr",
        component: () => import('@/views/dashboard/components/lh-mngr/LhMngr.vue'),
        meta: {
          requiresAuth: true
        },
      },
      /**
       *  단지 관리자
       */
      {
        path: "SbdMngr",
        name: "SbdMngr",
        component: () => import('@/views/dashboard/components/sbd-mngr/SbdMngr.vue'),
        meta: {
          requiresAuth: true
        },
      },
    ]
  }
];