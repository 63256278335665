import Vue from "vue";
import { ToastPlugin, ModalPlugin, ButtonPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import i18n from '@/libs/i18n'
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vee-validate";
import "@/libs/vue-cookies";
import "@/libs/dayjs";
import "@/libs/vue-jwt-decode";
import "@/libs/vue-js-modal";
import "@/libs/vue-tippy";
import "@/libs/autocomplete-vue";
import "@/libs/print";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(ButtonPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = true;
Vue.config.devtools = true;

// Mock Service Workcer 
// if (process.env.NODE_ENV === 'local') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
//   console.log('Mock Service Workcer Start!!')
// }

export default new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
