/**
 *  아파트 관리
 */
export default [
  /**
   *  DR 발령 현황
   */
  {
    path: '/apt-mng/dr-goo-xpn/DrGooXpnDtl',
    name: 'DrGooXpnDtl',
    component: () => import('@/views/apt-mng/dr-goo-xpn/DrGooXpnDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/dr-goo-xpn/DrGooXpnList',
    name: 'DrGooXpnList',
    component: () => import('@/views/apt-mng/dr-goo-xpn/DrGooXpnList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  전자 투표
   */
  {
    path: '/apt-mng/ervt/ErvtAdd',
    name: 'ErvtAdd',
    component: () => import('@/views/apt-mng/ervt/ErvtAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/ervt/ErvtUpd',
    name: 'ErvtUpd',
    component: () => import('@/views/apt-mng/ervt/ErvtUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/ervt/ErvtList',
    name: 'ErvtList',
    component: () => import('@/views/apt-mng/ervt/ErvtList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/ervt/ErvtRsl',
    name: 'ErvtRsl',
    component: () => import('@/views/apt-mng/ervt/ErvtRsl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/ervt/ErvtXpn',
    name: 'ErvtXpn',
    component: () => import('@/views/apt-mng/ervt/ErvtXpn.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  관리비
   */
  {
    path: '/apt-mng/mngexp/MngexpDtl',
    name: 'MngexpDtl',
    component: () => import('@/views/apt-mng/mngexp/MngexpDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/mngexp/MngexpEdt',
    name: 'MngexpEdt',
    component: () => import('@/views/apt-mng/mngexp/MngexpEdt.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/mngexp/MngexpList',
    name: 'MngexpList',
    component: () => import('@/views/apt-mng/mngexp/MngexpList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  임대료
   */
  {
    path: '/apt-mng/rfe/RfeDtl',
    name: 'RfeDtl',
    component: () => import('@/views/apt-mng/rfe/RfeDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/rfe/RfeEdt',
    name: 'RfeEdt',
    component: () => import('@/views/apt-mng/rfe/RfeEdt.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/rfe/RfeList',
    name: 'RfeList',
    component: () => import('@/views/apt-mng/rfe/RfeList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  입주민 공지사항
   */
  {
    path: '/apt-mng/rsd-nc/RsdNcDtl',
    name: 'RsdNcDtl',
    component: () => import('@/views/apt-mng/rsd-nc/RsdNcDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/rsd-nc/RsdNcAdd',
    name: 'RsdNcAdd',
    component: () => import('@/views/apt-mng/rsd-nc/RsdNcAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/rsd-nc/RsdNcUpd',
    name: 'RsdNcUpd',
    component: () => import('@/views/apt-mng/rsd-nc/RsdNcUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apt-mng/rsd-nc/RsdNcList',
    name: 'RsdNcList',
    component: () => import('@/views/apt-mng/rsd-nc/RsdNcList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
