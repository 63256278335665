import { 
  reactive, getCurrentInstance, watch, toRefs
} from '@vue/composition-api'

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
/**
 * 현재 Vue 인스턴스에서 반응적인 route 객체와 router 인스턴스를 반환합니다.
 * Composition API에서 현재 라우트와 라우터 기능에 접근할 수 있도록 합니다.
 * @returns {Object} 반응적인 route 객체와 router 인스턴스를 포함한 객체를 반환합니다.
 */
export const useRouter = () => {
  const vm = getCurrentInstance().proxy;
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r;
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * 현재 Vue 인스턴스에서 번역 함수 t를 반환합니다.
 * i18n이 구성되어 있다면 $t 메서드를 사용하여 번역을 수행합니다.
 * 구성되어 있지 않은 경우에는 원래의 키 값을 반환합니다.
 * @returns {Object} 번역 함수 t를 포함한 객체를 반환합니다.
 */
export const useI18nUtils = () => {
  const t = key => {
    const vm = getCurrentInstance().proxy;
    return vm.$t ? vm.$t(key) : key;
  }

  return { t }
}

export const useDayjsUtils = () => {
  /**
   * Vue 인스턴스에서 Day.js가 등록되어 있으면 해당 인스턴스에서 Day.js 메서드를 호출하고,
   * 등록되어 있지 않으면 입력된 key를 그대로 반환합니다.
   * @param {string} key - 호출하려는 Day.js 메서드 또는 키
   * @returns {*} - Day.js 메서드의 결과 또는 입력된 키
   */
  const dayjs = key => {
    const vm = getCurrentInstance().proxy;
    return vm.$dayjs ? vm.$dayjs(key) : key;
  }

  /**
   * 날짜와 시간을 특정 포맷으로 변환하는 함수
   * 
   * @param {string} inputDateTime - 변환할 날짜와 시간 값
   * @param {string} outputFormat - 원하는 날짜와 시간의 출력 포맷
   * @param {string} [inputFormat=YYYYMMDDHHmmss] - 입력된 날짜와 시간의 현재 포맷 (기본값: 'YYYYMMDDHHmmss')
   * @returns {string} - 변환된 날짜와 시간 값 (변환 실패 시 빈 문자열 반환)
   */
  const formatDatetime = (inputDatetime, outputFormat, inputFormat = 'YYYYMMDDHHmmss') => {
      return inputDatetime && dayjs(inputDatetime).isValid()
          ? dayjs(inputDatetime, inputFormat).format(outputFormat)
          : '';
  }

  return { dayjs, formatDatetime }
}

export const useCmnUtils = () => {
  /**
   * 값이 null, undefined 또는 빈 값('')이면 기본값을 반환합니다.
   * @param {*} value - 확인할 값입니다.
   * @param {*} defaultValue - 값이 null, undefined 또는 빈 값인 경우 반환할 기본값입니다.
   * @returns {*} - 값이 null, undefined 또는 빈 값이면 기본값을 반환합니다.
   */
  const nullishCoalesce = (value, defaultValue) => {
    return value !== null && value !== undefined && value !== '' ? value : defaultValue;
  }

  /**
   * 주어진 객체가 비어있는지 확인합니다.
   * 비어있는 객체란 자신의 열거 가능한 속성이 없는 객체를 의미합니다.
   * @param {Object} obj - 비어있는지 확인하고자 하는 객체입니다.
   * @returns {boolean} 객체가 비어있는 경우 true를 반환하고, 그렇지 않은 경우 false를 반환합니다.
   */
  const isEmptyObject = (value) => {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
  }

  /**
   * 주어진 ID에 대응하는 이름을 찾아 반환하는 함수입니다.
   * @param {string} id - 찾고자 하는 ID 값
   * @param {Array} nameMap - ID와 이름이 매핑된 객체 배열
   * @returns {string} - ID에 대응하는 이름을 반환하며, ID가 없으면 빈 문자열을 반환합니다.
   */
  const findNameById = (id, nameMap) => {
    const entry = nameMap.find(item => item.id === id);
    return entry ? entry.name : '';
  }

  /**
   * 특정 cdGrpId에 해당하는 공통 코드 목록을 찾습니다.
   * @param {string} cdGrpId 찾고자 하는 코드 그룹 ID입니다.
   * @param {Object} cmnCd 코드 그룹 ID별로 정리된 코드 목록을 담고 있는 객체입니다.
   * @returns {Array} 주어진 cdGrpId에 맞는 코드 세부 정보의 배열을 반환하며, 해당하는 데이터가 없는 경우 빈 배열을 반환합니다.
   */
  const findCmnCdListById = (cdGrpId, cmnCd) => {
    return cmnCd[cdGrpId] || [];
  }

  return { nullishCoalesce, isEmptyObject, findNameById, findCmnCdListById }
}

export const useFormatUtils = () => {
  /**
   * 핸드폰 번호에 하이픈을 추가하는 함수
   * @param {string} phoneNumber - 하이픈을 추가할 핸드폰 번호
   * @returns {string} - 하이픈이 추가된 핸드폰 번호
   */
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
  }

  return { formatPhoneNumber };
}
