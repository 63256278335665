import cmnApi from '@/api/cmn/cmnApi'
import cookie from '@/libs/vue-cookies'
import { ApiResponseCode } from '@/constants/api-response-code'

const getInitState = () => {
  return {
    mngrInf: {},
    navMenu: [],
    currentMenu: {},
    grpList: [],
    sbdNmList: [],
    isLoading: false,
    searchQuery: {
      grpSn: '',
      sbdDs: '',
      sbdId: '',
      fetchDatetime: '',
    },
    evnCdList: [],
    bldIdList: [],
    cmnCd: {}
  }
}

export default {
  state: getInitState(),
  getters: {
    getMngrInf(state) {
      return state.mngrInf
    },
    getNavMenu(state) {
      return state.navMenu
    },
    getCurrentMenu(state) {
      return state.currentMenu
    },
    getGrpList(state) {
      return state.grpList
        // TODO 본사(전국) 그룹 노출x(확인 후 제거)
        .filter(v => v.grpSn !== '11')
        .map(v => ({
          id: v.grpSn,
          name: v.grpNm,
          grpDsCd: v.grpDsCd,
      }));
    },
    getSbdNmList(state) {
      return state.sbdNmList
    },
    getIsLoading(state) {
      return state.isLoading
    },
    getSearchQuery(state) {
      return state.searchQuery
    },
    getEvnCdList(state) {
      return state.evnCdList.map(v => ({
        id: v.evnCd,
        name: v.evnNm,
        evnTpCd: v.evnTpCd,
      }));
    },
    getBldIdList(state) {
      return state.bldIdList.map(v => ({
        id: v.bldId,
        name: v.dngDs,
      }));
    },
    getCmnCd(state) {
      return state.cmnCd
    }
  },
  mutations: {
    INIT_STATE(state) {
      Object.assign(state, getInitState())
    },
    INIT_SBD_NM_LIST(state) {
      state.sbdNmList = []
    },
    INIT_SBD_FL(state) {
      state.sbdNmList = []
    },
    SET_MNGR_INF(state, payload) {
      state.mngrInf = payload
    },
    SET_NAV_MENU(state, payload) {
      state.navMenu = payload
    },
    SET_CURRENT_MENU(state, payload) {
      state.currentMenu = payload
    },
    SET_GRP_LIST(state, { grpList }) {
      state.grpList = grpList
    },
    SET_SBD_NM_LIST(state, { sbdNmList, hnetFrmSn }) {
      state.sbdNmList = sbdNmList.map(({ sbdId, hnetSbdNm, sbdNm }) => ({
        id: sbdId,
        name: hnetFrmSn ? hnetSbdNm : sbdNm
      }))
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_SEARCH_QUERY(state, { grpSn, sbdDs, sbdId, fetchDatetime }) {
      state.searchQuery.grpSn = grpSn;
      state.searchQuery.sbdDs = sbdDs;
      state.searchQuery.sbdId = sbdId;
      if (fetchDatetime) state.searchQuery.fetchDatetime = fetchDatetime;
    },
    SET_EVN_CD_LIST(state, { evnCdList }) {
      state.evnCdList = evnCdList
    },
    SET_BLD_ID_LIST(state, { bldIdList }) {
      state.bldIdList = bldIdList
    },
    SET_CMN_CD(state, { cmnCd }) {
      state.cmnCd = cmnCd
    }
  },
  actions: {
    async initState({ commit }) {
      commit('INIT_STATE')
    },
    async initSbdNmList({ commit }) {
      commit('INIT_SBD_NM_LIST')
    },
    async initSbdFl({ commit }) {
      commit('INIT_SBD_FL')
    },
    async fetchMngrInf({ commit }, { mngrId }) {
      const { data } = await cmnApi.fetchMngrInf({ mngrId })
      commit('SET_MNGR_INF', data.resData)
      // 단지 관리자 일경우 단지 대시보드에서 검색필터가 존재 하지 않아 검색 조건 미리 셋팅
      if (data.resData.rolId === 'SBD_MNGR') {
        commit('SET_SEARCH_QUERY', { grpSn: data.resData.grpSn, sbdId: data.resData.sbdId, fetchDatetime: new Date() })
      }
      cookie.saveMngrInf(data.resData)
      return data
    },
    async fetchNavMenu({ commit }) {
      const { data } = await cmnApi.fetchNavMenu()
      // 리스트 데이터를 계층형 메뉴 구조로 변환
      commit('SET_NAV_MENU', transformMenuData(data.resData))
      return data
    },
    async setCurrentMenu({ commit }, payload) {
      // 시스템 접속내역 저장
      await cmnApi.inputSystemRecord({
        mngrId: payload.mngrId,
        mnuId: payload.mnuId
      });
      commit('SET_CURRENT_MENU', payload);
    },
    async fetchGrpList({ commit }) {
      const { data } = await cmnApi.fetchGrpList();
      commit('SET_GRP_LIST', { grpList: data.resData });
    },
    async fetchSbdNmList({ commit }, payload) {
      const { data } = await cmnApi.fetchSbdNmList(payload);
      if (data.resCd === ApiResponseCode.SUCCESS) {
        commit('SET_SBD_NM_LIST', { sbdNmList: data.resData, ...(payload?.hnetFrmSn && { hnetFrmSn: payload.hnetFrmSn }) });
      } else {
        commit('SET_SBD_NM_LIST', { sbdNmList: [] });
      }
    },
    async fetchDngDsList(_, payload) {
      const { data } = await cmnApi.fetchDngDsList(payload);
      return data;
    },
    async downloadFile(_, payload) {
      const response = await cmnApi.fetchFile(payload);

      const url = window.URL.createObjectURL(new Blob([response.data]));

      // eslint-disable-next-line no-unused-vars
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload.otxtFlNm);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async getFileBlobUrl(_, payload) {
      const response = await cmnApi.fetchFile(payload);
      return window.URL.createObjectURL(new Blob([response.data]));
    },
    async setSearchQuery({ commit }, payload) {
      commit('SET_SEARCH_QUERY', {
        grpSn: payload.grpSn,
        sbdDs: payload.sbdDs,
        sbdId: payload.sbdId,
        fetchDatetime: payload.fetchDatetime
      })
    },
    async inputUserRecord(_, payload) {
      const { data } = await cmnApi.inputUserRecord(payload);
      return data;
    },
    async fetchEvnCdList({ commit }, payload) {
      const { data } = await cmnApi.fetchEvnCdList();
      commit('SET_EVN_CD_LIST', { evnCdList: data.resData });
    },
    async fetchBldIdList({ commit }, payload) {
      const { data } = await cmnApi.fetchBldIdList(payload);
      commit('SET_BLD_ID_LIST', { bldIdList: data.resData });
    },
    async fetchCmnCd({ commit }, payload) {
      const { data } = await cmnApi.fetchCmnCd(payload);
      commit('SET_CMN_CD', { cmnCd: transformCmnCdList(data.resData) });
    }
  }
}

// 리스트 데이터를 계층형 메뉴 구조로 변환하는 함수
const transformMenuData = menuList => {
  if (!menuList) return [];

  const menuMap = new Map();

  menuList.forEach(menuItem => {
    const { mnuId, uppMnuId, mnuNm, scrnPth, mnuLvl, mnuFncCds, mnuImg } = menuItem;
    const key = mnuLvl === 1 ? 'header' : 'title';

    let menuData = { mnuId, [key]: mnuNm, route: scrnPth, mnuLvl, mnuFncCds, icon: mnuImg };
    // TODO 추후 API 통해서 역할별 대시보드 scrnPth 다르게 가져와야함
    if (scrnPth === '/dashboard/Dashboard') {
      const mngrInf = cookie.getMngrInf();
      if (mngrInf) {
        menuData.route = mngrInf.rolId === 'SBD_MNGR' ? '/dashboard/Dashboard/SbdMngr' : '/dashboard/Dashboard/LhMngr';
      }
    }
    if (scrnPth === '/rpt/opr-rpt/OprRpt') {
      const mngrInf = cookie.getMngrInf();
      if (mngrInf) {
        menuData.route = mngrInf.rolId === 'SBD_MNGR' ? '/rpt/opr-rpt/OprRpt/SbdMngrRpt' : '/rpt/opr-rpt/OprRpt/LhMngrRpt';
      }
    }

    if (uppMnuId) {
      if (menuMap.has(uppMnuId)) {
        if (!('children' in menuMap.get(uppMnuId))) {
          menuMap.get(uppMnuId).children = [];
        }
        menuMap.get(uppMnuId).children.push(menuData);
      } else {
        menuMap.set(uppMnuId, { children: [menuData] });
      }
    }

    menuMap.set(mnuId, menuData);
  });

  return [...menuMap.values()].filter(menuItem => menuItem.mnuLvl === 1);
}

const transformCmnCdList = (cmnCdList) => {
  const transformedData = {};

  cmnCdList.forEach(({ cdGrpId, cdDtlId, cdDtlNm }) => {
    // cdGrpId를 키로 가지는 배열이 없다면 빈 배열로 초기화
    transformedData[cdGrpId] = transformedData[cdGrpId] || [];

    // cdGrpId의 배열에 객체 추가
    transformedData[cdGrpId].push({
        id: cdDtlId,
        name: cdDtlNm
    });
  });
  
  return transformedData;
};
