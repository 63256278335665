/**
 * 모달 확인 창
 * @param {*} vm - Vue 인스턴스입니다.
 * @param {*} content - 창에 표시할 내용입니다.
 * @param {*} okTitle - 확인 버튼의 제목입니다.
 * @param {*} callback - 사용자가 확인 버튼을 클릭한 후 실행될 콜백 함수입니다.
 * @param {*} option - 창을 사용자 정의하기 위한 선택적 매개변수입니다.
 * @returns {Promise} - 사용자의 응답으로 해결(resolve)되는 프로미스입니다.
 */
export const msgConfirmBox = async (vm, content, okTitle, callback, option = {}) => {
  const h = vm.$createElement
  const messageVNode = h('div', { domProps: { innerHTML: content } })

  const defaultOption = {
    title: '알림',
    size: 'sm',
    buttonSize: 'sm',
    // okVariant: 'danger',
    okTitle,
    cancelTitle: '취소',
    dialogClass: 'cmn-modal-dialog',
    contentClass: 'cmn-modal-content',
    titleClass: 'cmn-modal-title',
    bodyClass: 'cmn-modal-body',
    footerClass: 'p-2 cmn-modal-footer',
    hideHeaderClose: false,
    centered: true,
    ...option,
  }

  return new Promise((resolve, reject) => {
    vm.$bvModal.msgBoxConfirm(messageVNode, defaultOption).then(value => {
      if (callback) {
        callback(value)
      }
      resolve(value)
    })
  })
}

/**
 * 모달 경고 창
 * @param {*} vm - Vue 인스턴스입니다.
 * @param {*} content - 창에 표시할 내용입니다.
 * @param {*} option - 창을 사용자 정의하기 위한 선택적 매개변수입니다.
 */
export const msgAlertBox = (vm, content, option = {}) => {
  const h = vm.$createElement
  const messageVNode = h('div', { domProps: { innerHTML: content } })

  const defaultOption = {
    hideHeaderClose: false,
    centered: true,
    title: '알림',
    okTitle: '확인',
    dialogClass: 'cmn-modal-dialog',
    titleClass: 'cmn-modal-title',
    contentClass: 'cmn-modal-content',
    bodyClass: 'cmn-modal-body',
    footerClass: 'p-2 cmn-modal-footer',
    ...option,
  }

  return vm.$bvModal.msgBoxOk(messageVNode, defaultOption);
}
