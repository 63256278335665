/**
 *  이벤트 관리
 */
export default [
  /**
   *  이벤트 분석
   */
  {
    path: '/evn-mng/evn-anl/EvnAnl',
    name: 'EvnAnl',
    component: () => import('@/views/evn-mng/evn-anl/EvnAnl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  이벤트 이력
   */
  {
    path: '/evn-mng/evn-hst/EvnHstList',
    name: 'EvnHstList',
    component: () => import('@/views/evn-mng/evn-hst/EvnHstList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  SMS 발송 내역
   */
  {
    path: '/evn-mng/sms-sndg-hst/SmsSndgHstList',
    name: 'SmsSndgHstList',
    component: () => import('@/views/evn-mng/sms-sndg-hst/SmsSndgHstList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
