import Vue from "vue";
import VueRouter from "vue-router";
// import axios from "axios";
import cookie from '@/libs/vue-cookies';
import store from '@/store';
import { msgAlertBox } from '@/utils/modal';
import { useCmnUtils } from '@/utils/util';

// Routes
import cmn from '@/router/routes/cmn'
import aptMng from '@/router/routes/apt-mng'
import contMng from '@/router/routes/cont-mng'
import dashboard from '@/router/routes/dashboard'
import evnMng from '@/router/routes/evn-mng'
import hc from '@/router/routes/hc'
import login from '@/router/routes/login'
import rpt from '@/router/routes/rpt'
import sbdMng from '@/router/routes/sbd-mng'
import sysInf from '@/router/routes/sys-inf'

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush
        .call(this, location)
        .catch(() => window.location.reload())
};

const router = new VueRouter({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
      ...cmn,
      ...dashboard,
      ...aptMng,
      ...contMng,
      ...evnMng,
      ...hc,
      ...login,
      ...rpt,
      ...sbdMng,
      ...sysInf,
	  ],
});

const findMenuByRoute = async (menu, route) => {
  for (const item of menu) {
    if (item.route === route) {
      return item;
    } else if (item.children) {
      const foundInChild = await findMenuByRoute(item.children, route);
      if (foundInChild) {
        return foundInChild;
      }
    }
  }
  return null;
}

router.beforeEach(async (to, from, next) => {
  // https://github.com/vuejs/vue-router/issues/2672
  await Vue.nextTick();
  
  // 토큰 확인
  const accessToken = cookie.getAccessToken();
  if (to.meta.requiresAuth && !accessToken) {
    await msgAlertBox(router.app, '로그인 후 이용해주세요.');
    next('/login/Login');
  } else next();
  
  // 인증 정보 확인
  if (to.meta.requiresAuth) {
    const { isEmptyObject } = useCmnUtils();
    // 네비게이션 메뉴 확인
    if (store.getters['cmn/cmnStore/getNavMenu'].length === 0) {
      await store.dispatch('cmn/cmnStore/fetchNavMenu');
    }

    // 유저 정보 확인
    if (isEmptyObject(store.getters['cmn/cmnStore/getMngrInf'])) {
      const payload = router.app.$jwtDec.decode(accessToken);
      await store.dispatch('cmn/cmnStore/fetchMngrInf', { mngrId: payload.sub });
    }

    // 현재 메뉴 셋팅
    const mngrInf = store.getters['cmn/cmnStore/getMngrInf'];
    // 네비게이션 메뉴에서 요청 PATH로 메뉴 정보 취득
    const menu = await findMenuByRoute(store.getters['cmn/cmnStore/getNavMenu'], to.path);
    if (menu) {
      await store.dispatch('cmn/cmnStore/setCurrentMenu', { 
        mnuId: menu.mnuId, 
        mnuNm: menu.title, 
        mnuFncCds: menu.mnuFncCds,
        mngrId: mngrInf.mngrId
      });
    }
    
    // 그룹 리스트 확인
    if (store.getters['cmn/cmnStore/getGrpList'].length === 0) {
      await store.dispatch('cmn/cmnStore/fetchGrpList');
    }

    // 공통 코드 확인
    if (isEmptyObject(store.getters['cmn/cmnStore/getCmnCd'])) {
      await store.dispatch('cmn/cmnStore/fetchCmnCd');
    }

    // 이벤트 코드 리스트 확인
    // 입주지원팀장(MVIN_SPPT), 홈넷설치자(HNETCO_ISTLR) 이벤트 권한 X
    const exceptRole = ['MVIN_SPPT', 'HNETCO_ISTLR', 'FNS_ISTLR']
    if (!exceptRole.includes(mngrInf.rolId) && store.getters['cmn/cmnStore/getEvnCdList'].length === 0) {
      await store.dispatch('cmn/cmnStore/fetchEvnCdList');
    }

    // 건물 아이디(bld_id) 리스트 확인(동 맵핑용)
    if (mngrInf.rolId === 'SBD_MNGR' && store.getters['cmn/cmnStore/getBldIdList'].length === 0) {
      await store.dispatch('cmn/cmnStore/fetchBldIdList', { sbdId: mngrInf.sbdId });
    }
  }
  
  // 검색조건에 따른 LH 대시보드 라우팅
  if (to.meta.requiresAuth && to.name === 'LhMngr') {
    const { grpSn, sbdId } = store.getters['cmn/cmnStore/getSearchQuery'];
    if (grpSn && sbdId) {
      next({ name: 'SbdMngr' });
    } else next();
  }

})
  
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
