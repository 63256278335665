/**
 *  헬스케어
 */
export default [
  /**
   *  컨텐츠 관리
   */
  {
    path: '/hc/cnts-mng/CntsMngDtl',
    name: 'CntsMngDtl',
    component: () => import('@/views/hc/cnts-mng/CntsMngDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hc/cnts-mng/CntsMngAdd',
    name: 'CntsMngAdd',
    component: () => import('@/views/hc/cnts-mng/CntsMngAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hc/cnts-mng/CntsMngUpd',
    name: 'CntsMngUpd',
    component: () => import('@/views/hc/cnts-mng/CntsMngUpd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hc/cnts-mng/CntsMngList',
    name: 'CntsMngList',
    component: () => import('@/views/hc/cnts-mng/CntsMngList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
