export default [
  /**
   *  보고서
   */
  {
    path: '/rpt/opr-rpt/OprRpt',
    component: () => import('@/views/rpt/opr-rpt/OprRpt.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      /**
       *  LH 관리자
       */
      {
        path: "LhMngrRpt",
        name: "LhMngrRpt",
        component: () => import('@/views/rpt/opr-rpt/components/lh-mngr/LhMngrRpt.vue'),
        meta: {
          requiresAuth: true
        },
      },
      /**
       *  단지 관리자
       */
      {
        path: "SbdMngrRpt",
        name: "SbdMngrRpt",
        component: () => import('@/views/rpt/opr-rpt/components/sbd-mngr/SbdMngrRpt.vue'),
        meta: {
          requiresAuth: true
        },
      },
    ]
  }
];