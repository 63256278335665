/**
 *  제어 관리
 */
export default [
  /**
   *  기기 제어 분석
   */
  {
    path: '/cont-mng/dvc-cont-anl/DvcContAnl',
    name: 'DvcContAnl',
    component: () => import('@/views/cont-mng/dvc-cont-anl/DvcContAnl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  기기 제어 현황
   */
  {
    path: '/cont-mng/dvc-cont-xpn/DvcContXpnDtl',
    name: 'DvcContXpnDtl',
    component: () => import('@/views/cont-mng/dvc-cont-xpn/DvcContXpnDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cont-mng/dvc-cont-xpn/DvcContXpnList',
    name: 'DvcContXpnList',
    component: () => import('@/views/cont-mng/dvc-cont-xpn/DvcContXpnList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  기기 스케줄링 이력
   */
  {
    path: '/cont-mng/dvc-schdl-hst/DvcSchdlHstList',
    name: 'DvcSchdlHstList',
    component: () => import('@/views/cont-mng/dvc-schdl-hst/DvcSchdlHstList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  기기 스케줄링 현황
   */
  {
    path: '/cont-mng/dvc-schdl-xpn/DvcSchdlXpnList',
    name: 'DvcSchdlXpnList',
    component: () => import('@/views/cont-mng/dvc-schdl-xpn/DvcSchdlXpnList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
