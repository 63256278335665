import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const loadLocaleMessages = () => {
  const messages = {}
  const locales = importAll(require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i))

  Object.entries(locales).forEach(([key, value]) => {
    const locale = key.match(/([A-Za-z0-9-_]+)\./i)[1]
    messages[locale] = { ...value }
  })

  return messages
}

const importAll = (context) => {
  const keys = context.keys()
  const values = keys.map(context)
  return keys.reduce((acc, key, index) => ({ ...acc, [key]: values[index] }), {})
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ko',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ko',
  messages: loadLocaleMessages(),
})
