/**
 *  로그인
 */
export default [
  {
    path: "/login",
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: "full",
    },
    children: [
      {
        path: "",
        redirect: "/login/Login",
      },
      {
        path: "Login",
        name: "LoginFrm",
        component: () => import('@/views/login/components/LoginFrm.vue'),
        meta: {
          layout: "full",
        },
      },
      {
        path: "Register",
        name: "RegisterFrm",
        component: () => import('@/views/login/components/RegisterFrm.vue'),
        meta: {
          layout: "full",
        },
      },
      {
        path: "ResetPwd",
        component: () => import('@/views/login/components/ResetPwd.vue'),
        meta: {
          layout: "full",
        },
        children: [
          {
            path: "",
            redirect: "/login/ResetPwd/ResetPwdFrm1",
          },
          {
            path: "ResetPwdFrm1",
            name: "ResetPwdFrm1",
            component: () => import('@/views/login/components/ResetPwdFrm1.vue'),
            meta: {
              layout: "full",
            },
          },
          {
            path: "ResetPwdFrm2",
            name: "ResetPwdFrm2",
            component: () => import('@/views/login/components/ResetPwdFrm2.vue'),
            meta: {
              layout: "full",
            },
          },
          {
            path: "ResetPwdFrm3",
            name: "ResetPwdFrm3",
            component: () => import('@/views/login/components/ResetPwdFrm3.vue'),
            meta: {
              layout: "full",
            },
          },
        ]
      }
    ]
  }
]
