import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Modules
import modules from '@/store/module'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        ...modules,
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: ['cmn', 'login']
        })
    ],    
    strict: process.env.DEV,
})