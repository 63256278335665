/**
 *  시스템 정보
 */
export default [
  /**
   *  자료실
   */
  {
    path: '/sys-inf/arcv/ArcvDtl',
    name: 'ArcvDtl',
    component: () => import('@/views/sys-inf/arcv/ArcvDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sys-inf/arcv/ArcvList',
    name: 'ArcvList',
    component: () => import('@/views/sys-inf/arcv/ArcvList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  FAQ
   */
  {
    path: '/sys-inf/faq/FaqList',
    name: 'FaqList',
    component: () => import('@/views/sys-inf/faq/FaqList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /**
   *  시스템 공지사항
   */
  {
    path: '/sys-inf/sys-nc/SysNcDtl',
    name: 'SysNcDtl',
    component: () => import('@/views/sys-inf/sys-nc/SysNcDtl.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sys-inf/sys-nc/SysNcList',
    name: 'SysNcList',
    component: () => import('@/views/sys-inf/sys-nc/SysNcList.vue'),
    meta: {
      requiresAuth: true
    }
  },
]
