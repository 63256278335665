import Vue from 'vue'
import VueCookies from 'vue-cookies'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'

Vue.use(VueCookies)

const cookie = {
  /**
   * 쿠키를 저장합니다.
   * @param {string} name - 쿠키 이름
   * @param {string} value - 값
   * @param {string} value - 만료 시간
   */
  saveCookie(name, value, expireTime) {
    VueCookies.set(name, value, expireTime);
  },
  /**
   * 액세스 토큰을 저장합니다.
   * @param {string} value - 저장할 액세스 토큰 값입니다.
   */
  saveAccessToken(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieAccessTokenKeyName,
      value,
      jwtDefaultConfig.accessTokenExpireTime
    );
  },
  /**
   * 기존 리프레시 토큰을 저장합니다.
   * @param {string} value - 저장할 기존 리프레시 토큰 값입니다.
   */
  saveOldRefreshToken(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieOldRefreshTokenKeyName,
      value,
      jwtDefaultConfig.refreshTokenExpireTime
    );
  },
  /**
   * 리프레시 토큰을 저장합니다.
   * @param {string} value - 저장할 리프레시 토큰 값입니다.
   */
  saveRefreshToken(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieRefreshTokenKeyName,
      value,
      jwtDefaultConfig.refreshTokenExpireTime
    );
  },
  /**
   * 관리자 정보를 저장합니다.
   * @param {string} value - 저장할 관리자 정보 값입니다.
   */
  saveMngrInf(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieMngrInfKeyName,
      value,
      jwtDefaultConfig.mngrInfExpireTime
    );
  },
  /**
   * 현재 메뉴 정보를 저장합니다.
   * @param {string} value - 저장할 현재 메뉴 정보 값입니다.
   */
  saveCurrentMenu(value) {
    VueCookies.set(
      jwtDefaultConfig.cookieCurrentMenuKeyName,
      value,
      jwtDefaultConfig.currentMenuExpireTime
    );
  },

  /**
   * 현재 쿠키 정보를 가져옵니다.
   * @param {string} name - 쿠키 이름
   * @returns {string|null} - 현재 쿠키 정보 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getCookie(name) {
    return VueCookies.get(name);
  },
  /**
   * 액세스 토큰을 가져옵니다.
   * @returns {string|null} - 액세스 토큰 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getAccessToken() {
    return VueCookies.get(jwtDefaultConfig.cookieAccessTokenKeyName);
  },
  /**
   * 기존 리프레시 토큰을 가져옵니다.
   * @returns {string|null} - 기존 리프레시 토큰 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getOldRefreshToken() {
    return VueCookies.get(jwtDefaultConfig.cookieOldRefreshTokenKeyName);
  },
  /**
   * 리프레시 토큰을 가져옵니다.
   * @returns {string|null} - 리프레시 토큰 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getRefreshToken() {
    return VueCookies.get(jwtDefaultConfig.cookieRefreshTokenKeyName);
  },
  /**
   * 관리자 권한 정보를 가져옵니다.
   * @returns {string|null} - 관리자 권한 정보 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getMngrInf() {
    return VueCookies.get(jwtDefaultConfig.cookieMngrInfKeyName);
  },
  /**
   * 현재 메뉴 정보를 가져옵니다.
   * @returns {string|null} - 현재 메뉴 정보 값입니다. 저장된 값이 없을 경우 null을 반환합니다.
   */
  getCurrentMenu() {
    return VueCookies.get(jwtDefaultConfig.cookieCurrentMenuKeyName);
  },

  /**
   * 쿠키를 삭제합니다.
   * @param {string} name - 쿠키 이름
   */
  delCookie(name) {
    VueCookies.remove(name);
  },
  /**
   * 액세스 토큰을 삭제합니다.
   */
  delAccessToken() {
    VueCookies.remove(jwtDefaultConfig.cookieAccessTokenKeyName);
  },
  /**
   * 기존 리프레시 토큰을 삭제합니다.
   */
  delOldRefreshToken() {
    VueCookies.remove(jwtDefaultConfig.cookieOldRefreshTokenKeyName);
  },
  /**
   * 리프레시 토큰을 삭제합니다.
   */
  delRefreshToken() {
    VueCookies.remove(jwtDefaultConfig.cookieRefreshTokenKeyName);
  },
  /**
   * 관리자 권한 정보를 삭제합니다.
   */
  delMngrInf() {
    VueCookies.remove(jwtDefaultConfig.cookieMngrInfKeyName);
  },
  /**
   * 현재 메뉴 정보를 삭제합니다.
   */
  delCurrentMenu() {
    VueCookies.remove(jwtDefaultConfig.cookieCurrentMenuKeyName);
  },
};

export default cookie
