import _ from 'lodash';
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

/* 용지 별 규격(mm) */
const paperStandardSize = {
    a1: [594, 841],
    a2: [420, 594],
    a3: [297, 420],
    a4: [210, 297],
    a5: [148, 210],
    a6: [105, 148],
    b1: [788, 1091],
    b2: [788, 545],
    b3: [545, 394],
    b4: [394, 272],
    b5: [272, 197],
    b6: [197, 136],
};

class initOptions {
    _canvasOptions = {
        logging: false,   // 디버그 목적 로그
        allowTaint: true, // cross-origin allow
        useCORS: true,    // CORS 사용한 서버로부터 이미지 로드할 것인지 여부
        fileName: '',
        height: 0,
        width: 0,
        scale: 1.3,
        dpi: 600,
        scrollX: 0,
        scrollY: 0,
        fileExtension: "pdf",
    }

    constructor(canvasOptions) {
        this.canvasOptions = _.merge(this._canvasOptions, canvasOptions);
    }

    get canvasOptions() {
        return this._canvasOptions;
    }

    set canvasOptions(value) {
        this._canvasOptions = _.merge(this._canvasOptions, value);
    }
}

export default {
    install(Vue) {

/**********************************************
  pdf export
*******************************************************/
        Vue.prototype.$exportPrint = (element, pageDirection) => {

            /* export 할 element Id 미지정 시 return */
            if (element === null || element === undefined) {
                return console.log("DOM ID를 입력해 주세요.");
            }

            /* export 할 element Id 명 */
            const elementDOM = document.querySelector(element);
            let editedOptions = new initOptions({});
            /* 선택한 element Id의 가로 세로 폭 (캡쳐 이미지 사이즈 지정) */
            editedOptions.canvasOptions.height = elementDOM.height;
            editedOptions.canvasOptions.width = elementDOM.width
            
            if (paperStandardSize.a4[0] > paperStandardSize.a4[1]) {
                editedOptions.imgWidth = paperStandardSize.a4[0];
                editedOptions.imgHeight = paperStandardSize.a4[1];
            } else {
                editedOptions.imgWidth = paperStandardSize.a4[1];
                editedOptions.imgHeight = paperStandardSize.a4[0];
            }

            html2canvas(elementDOM, editedOptions.canvasOptions).then(canvas => {
                const my_window = window.open("", '_blank', '');
                my_window.document.body.appendChild(canvas);
                my_window.focus();
                my_window.print();
                my_window.close();
            });

        };

/**********************************************
   pdf, img export
*******************************************************/

        Vue.prototype.$pdfExport = function (element, letterSize, pageDirection, options) {

            /* export 할 element Id 미지정 시 return */
            if (element === null || element === undefined) {
                return console.log("DOM ID를 입력해 주세요.");
            }

            /* export 할 element Id 명 */
            const elementDOM = document.querySelector(element);

            /*  기본 옵션  */
            let editedOption = new initOptions(options);

            /* 선택한 element Id의 가로 세로 폭 (캡쳐 이미지 사이즈 지정) */
            editedOption.canvasOptions.height = elementDOM.scrollHeight;
            editedOption.canvasOptions.width = elementDOM.scrollWidth;

            switch (pageDirection.toLowerCase()) {
                case "l":
                    if (paperStandardSize[letterSize][0] > paperStandardSize[letterSize][1]) {
                        editedOption.imgWidth = paperStandardSize[letterSize][0];
                        editedOption.imgHeight = paperStandardSize[letterSize][1];
                    } else {
                        editedOption.imgWidth = paperStandardSize[letterSize][1];
                        editedOption.imgHeight = paperStandardSize[letterSize][0];
                    }
                    break;
                case "p" :
                    if (paperStandardSize[letterSize][0] < paperStandardSize[letterSize][1]) {
                        editedOption.imgWidth = paperStandardSize[letterSize][0];
                        editedOption.imgHeight = paperStandardSize[letterSize][1];
                    } else {
                        editedOption.imgWidth = paperStandardSize[letterSize][1];
                        editedOption.imgHeight = paperStandardSize[letterSize][0];
                    }
                    break;
                default:
                    return console.log("용지 방향을 가로(l), 세로(p)로 구분해주세요. ")
            }

            const exportSnapshot = ({canvas, fileName, type, replaceValue = ''}) => {
                let a = document.createElement('a');
                a.href = canvas.toDataURL(type).replace(type, replaceValue);
                a.download = fileName;
                a.click();
            }

            /* 클라이언트의 성격에 따라 pdf, png 파일을 구분하여 받는 분기문 (기본은 pdf)  */
            switch (editedOption.canvasOptions.fileExtension) {
                case "jpg" :
                case "jpeg":
                    html2canvas(elementDOM, editedOption.canvasOptions).then(canvas => {

                        const fileName = `${editedOption.canvasOptions.fileName}Jpeg_Export.jpeg`
                        const type = "image/jpeg"
                        const replaceValue = "image/octet-stream"
                        exportSnapshot({canvas, fileName, type, replaceValue})
                    });
                    break;
                case "png":
                    html2canvas(elementDOM, editedOption.canvasOptions).then(canvas => {
                        const fileName = `${editedOption.canvasOptions.fileName}PNG_Export.png`
                        const type = "image/png"
                        exportSnapshot({canvas, fileName, type})
                    });
                    break;
                default:
                    const pdf = new jsPDF(pageDirection.toLowerCase(), 'mm', paperStandardSize[letterSize]);
                    const promises = [];
                    
                    for (let i = 0; i < 2; i++) {
                        const el = document.querySelector(`${element}-${i}`);
                        const promise = html2canvas(el, editedOption.canvasOptions).then(canvas => {
                            const imgData = canvas.toDataURL('image/png');
                            let imageWidth = canvas.width / (canvas.width / editedOption.imgWidth);
                            let imageHeight = (editedOption.imgWidth * canvas.height) / canvas.width;
                            return { imgData, imageWidth, imageHeight };
                        });
                        promises.push(promise);
                    }
                    
                    Promise.all(promises).then(list => {
                        list.forEach((item, i) => {
                            if (i > 0) pdf.addPage();
                            pdf.addImage(item.imgData, 'PNG', 0, 10, item.imageWidth, item.imageHeight);
                            if (i === 1) pdf.save(`${editedOption.canvasOptions.fileName}.pdf`);
                        });
                    });

                    break;
            }
        };
    }
};