import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, digits, numeric, email, confirmed } from 'vee-validate/dist/rules'

extend('limit', (value, params) => {
  const [min, max] = params
  if ((value && value.length < min) || value.length > max) {
    return `{_field_} 은(는) ${min}자 ~ ${max}자로 입력해 주세요`
  }
  return true
})

extend('max', (value, args) => {
  if (value.length > Number(args[0])) {
    return `{_field_} 은(는) ${args}자 이하로 입력해 주세요.`
  }
  return true
})
// 기존에 존재하던 required는 message만 변경
extend('required', {
  ...required,
  message: '{_field_} 는(은) 반드시 입력해야 합니다',
})

extend('required-select', {
  ...required,
  message: '{_field_} 는(은) 반드시 선택해야 합니다',
})

extend('numeric', {
  ...numeric,
  message: '{_field_} 는(은) 숫자로만 구성되어야 합니다',
})
extend('digits', {
  ...digits,
  message: '{_field_} 는(은) 11자리 여야 합니다',
})

extend('email', {
  ...email,
  message: '잘못 입력된 이메일 주소 입니다',
})

// provider 에 :rules="{ confirmed: 'provider name 명을 적어주면 된다'}"
extend('confirmed', {
  ...confirmed,
  message: '신규 비밀번호와 신규 비밀번호확인이 일치하지 않습니다',
})

extend('passwordChk', {
  validate: value => {
    /**
     *  암호 정책
     */
    // 8자리 이상 3종류 이상(영문,숫자,특수문자)
    const regex1 = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]+$/.test(value);
    // 10자리 이상 2종류(영문,숫자,특수문자 중 2개)
    const regex2 = /^(?:(?=.*[a-zA-Z])(?=.*\d)|(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])|(?=.*\d)(?=.*[!@#$%^&*()_+]))[a-zA-Z\d!@#$%^&*()_+]+$/.test(value);

    if (value && value.length < 8) {
      return '비밀번호는 8자 이상 입력해주세요.';
    } else if (value.length < 10 && !regex1) {
      return '비밀번호는 영문,숫자,특수문자가 포함되어야 합니다.';
    } else if (!regex2) {
      return '비밀번호는 영문,숫자,특수문자중 2종류 이상 포함되어야 합니다.';
    }

    return true;
  }
});

extend('after', {
  validate: (value, { startDate }) => {
    return new Date(value) > new Date(startDate);
  },
  params: ['startDate'],
  message: '종료일은 시작일 이후로 설정해야 합니다.',
})

extend('rgsNc', {
  validate: (value, { startDate }) => {
    return new Date(value) <= new Date(startDate);
  },
  params: ['startDate'],
  message: '알림 등록 일시는 시작일 이전으로 설정해야 합니다.',
})

extend('fnsNcConf', {
  validate: (value, { min, max }) => {
    return !(value < min || value > max);
  },
  params: ['min', 'max'],
  message: `{_field_} 는(은) 0 ~ 65535 사이만 입력 가능합니다.`,
})

extend('mac', {
  validate: value => {
    const macRegex = /^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$/;

    if (!macRegex.test(value)) {
      return '유효하지 않은 MAC 주소 형식입니다. ex) 00-1A-2B-3C-4D-5E';
    }

    return true;
  }
});

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
